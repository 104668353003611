<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>

          <b-breadcrumb-item active>
            登記預約中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BookingSelectList' }">
            預約管理
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{ "編輯預約設定檔" }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li
                :class="{
                  active: step == '',
                  'has-checkbox': true,
                  'has-checkbox--checked': bookingPreset.id,
                }"
                @click="goToStep('')"
              >
                <a>分店時段設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">
                    {{ "編輯預約設定檔" }} - {{ displayTitle }}
                  </h4>
                  <div class="col-12 col-xl-6 mb-3">
                    <AppSingleSelect
                      v-model="branch"
                      :options="branchOptions"
                      @input="formatAndFetchPreset"
                    />
                  </div>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預約時間"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="presetTimeField.format"
                        disabled
                      >
                        <b-form-radio value="datetime">日期＋時間</b-form-radio>
                        <b-form-radio value="date">日期</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="日期格式"
                      v-if="isInclude('date', presetTimeField.format)"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="presetTimeField.date_condition"
                        disabled
                      >
                        <b-form-radio value="unlimited">自行選擇日期</b-form-radio>
                        <b-form-radio value="limited">限定日期選項</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="日期呈現樣式"
                      v-if="isInclude('date', presetTimeField.format)"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="form.business.date_style"
                        disabled
                      >
                        <b-form-radio value="default">預設</b-form-radio>
                        <b-form-radio value="calendar_style">日曆</b-form-radio>
                        <b-form-radio value="select_style">下拉選單</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="日期區段設定"
                      v-if="isInclude('date', presetTimeField.format) && presetTimeField.date_condition == 'limited'"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="form.business.date_config_by"
                        @change="changeDateConfigBy"
                      >
                        <b-form-radio value="default">預設</b-form-radio>
                        <b-form-radio value="custom">自訂</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      v-if="(isInclude('date', presetTimeField.format) && presetTimeField.date_condition == 'limited')"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label=""
                    >
                      <VueDraggable
                          handle=".option-drag-handler"
                          :list="form.business.options"
                          :disabled="form.business.date_config_by == 'default'"
                        >
                          <div v-for="(option, optionIndex) in form.business.options" class="mb-3 d-flex align-items-center" :key="'date-opt-' + optionIndex">
                            <div class="flex-grow-1 flex-shrink-1 d-flex align-items-center">
                              <i class="fa fa-bars mr-3 option-drag-handler"></i>
                              <!-- <b-form-input
                                placeholder="請輸入日期"
                                v-model="form.business.options[optionIndex].text"
                                @change="syncOptionValue(optionIndex)"
                                @compositionstart="compositionStart"
                                @compositionend="compositionEnd(optionIndex)"
                                @keydown.enter="event => handleKeyDown({event, optionIndex})"
                                @paste="e => handlePaste(e, optionIndex)"
                                :ref="`date_${optionIndex}`"
                                :disabled="form.business.date_config_by == 'default'"
                              >
                              </b-form-input> -->

                              <AppDatePicker v-model="form.business.options[optionIndex].text" :disabled="form.business.date_config_by == 'default'"/>

                            </div>
                            <div class="flex-shrink-0">
                              <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-success" @click="createOption(optionIndex)" :disabled="form.business.date_config_by == 'default'">
                                <span class="mdi mdi-18px mdi-playlist-plus"></span>
                                增加
                              </b-button>
                              <b-button class="ml-2 p-1 d-inline-flex align-items-center" variant="inverse-danger" @click="removeOption(optionIndex)" :disabled="isOnlyOneOption() || form.business.date_config_by == 'default'">
                                <span class="mdi mdi-18px mdi-playlist-remove"></span>
                                移除
                              </b-button>
                              <b-button class="ml-2 p-1 d-inline-flex align-items-center btn-inverse-info" v-b-modal="'modal-create-date-option'" @click="dateOptionIndex = optionIndex;" :disabled="form.business.date_config_by == 'default'">
                                <span class="mdi mdi-18px mdi-calendar-plus"></span>
                                區間
                              </b-button>
                            </div>
                          </div>
                        </VueDraggable>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="時間格式"
                      v-if="isInclude('datetime', presetTimeField.format)"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="presetTimeField.time_condition"
                        disabled
                      >
                        <b-form-radio value="unlimited">自行選擇時間</b-form-radio>
                        <b-form-radio value="limited">限定時間選項</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="時間呈現樣式"
                      v-if="(presetTimeField.format == 'datetime') && (presetTimeField.time_condition == 'limited')"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="form.business.time_style"
                      >
                        <b-form-radio value="default">預設</b-form-radio>
                        <b-form-radio value="time_select_style" :disabled="true">時間下拉</b-form-radio>
                        <b-form-radio value="select_style" :disabled="presetTimeField.time_condition == 'unlimited'" >自訂下拉</b-form-radio>
                        <b-form-radio value="button_style" :disabled="presetTimeField.time_condition == 'unlimited'" >按鈕選項</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="時間區段設定"
                      v-if="(presetTimeField.format == 'datetime' && presetTimeField.time_condition == 'limited')"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        v-model="form.business.time_config_by"
                        @change="changeTimeConfigBy"
                      >
                        <b-form-radio value="default">預設</b-form-radio>
                        <b-form-radio value="custom">自訂</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>

                    <b-form-group
                      v-if="presetTimeField.format == 'datetime'"
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label=""
                    >
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-size="sm"
                        label="開始時間 *"
                      >
                        <AppTimePicker
                          styleType="unlimited"
                          v-model="form.business.start_time"
                          @input="generateTimeOptions"
                          :disabled="form.business.time_config_by == 'default'"
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-size="sm"
                        label="結束時間 *"
                      >
                        <AppTimePicker
                          styleType="unlimited"
                          v-model="form.business.end_time"
                          @input="generateTimeOptions"
                          :disabled="form.business.time_config_by == 'default'"
                        />
                      </b-form-group>
                      <b-form-group
                        label-cols="4"
                        label-cols-lg="4"
                        label-size="sm"
                        label="每個時間區段單位 *"
                      >
                        <b-form-input
                          class="mb-2 mr-sm-2 mb-sm-0"
                          type="number"
                          min="0"
                          v-model="form.business.slot_minutes"
                          @input="generateTimeOptions"
                          :disabled="form.business.time_config_by == 'default'"
                        ></b-form-input>
                      </b-form-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="人數限制"
                      v-if="presetLimitType == 'time' && isEnabledOccupySetting && presetTimeField.format == 'datetime'"
                    >
                      <b-form-radio-group
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        v-model="form.business.limit_type"
                        @change="generateTimeOptions"
                      >
                        <b-form-radio value="unlimit">不限</b-form-radio>
                        <b-form-radio value="limit_by_slot">依時段限定</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label=""
                      v-if="presetLimitType == 'time' && isEnabledOccupySetting &&  occupySetting.is_check_branch"
                    >
                      時段限定
                      <input
                        class="mb-2 mr-sm-2 mb-sm-0 w-4"
                        type="text"
                        v-model="form.business.slot_people"
                        @input="generateTimeOptions"
                      /> 人
                    </b-form-group>
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="預覽"
                      v-if="presetLimitType == 'time' && isEnabledOccupySetting && presetTimeField.format == 'datetime' &&  occupySetting.is_check_branch"
                    >
                      <div v-for="(timeOption, index) in form.time_options" v-bind:key="index">
                        <b-form-group
                          label-cols="6"
                          label-cols-lg="6"
                          label-size="sm"
                          :label="timeOption.title"
                        >
                          <b-form-input v-model="timeOption.value" :disabled="form.business.limit_type == 'unlimit'"></b-form-input>
                        </b-form-group>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    :disabled="disabledButton"
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button :disabled="disabledButton || (form.business.time_config_by === 'default' && form.business.date_config_by === 'default')" @click="submit" variant="success"> 儲存 </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->
    <b-modal
      id="modal-create-date-option"
      title="設定時間範圍"
      centered
      @hidden=";"
      @ok="createOptionByCalendar"
    >
      <form ref="form" @submit.stop.prevent=";">
        <AppDatePicker v-model="optionStartDate"/>
        ~
        <AppDatePicker v-model="optionEndDate"/>
      </form>

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">
          取消
        </b-button>
        <b-button size="sm" variant="success" @click="ok()">
          新增
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import bookingApi from "@/apis/booking";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
import { mapGetters } from "vuex";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
// import { required, minValue } from "@vuelidate/validators";
import AppTimePicker from '@/components/AppTimePicker.vue';
import AppDatePicker from '@/components/AppDatePicker.vue';
import VueDraggable from "vuedraggable";
import AppSingleSelect from "@/components/AppSingleSelect.vue";

export default {
  components: {
    AppTimePicker,
    VueDraggable,
    AppDatePicker,
    AppSingleSelect
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
    };
  },
  computed: {
    consts() {
      return consts;
    },
    ...mapGetters("route", ["routeQuery"]),
    ...mapGetters("general", ["myBranches"]),
    occupySetting() {
      return this.bookingPreset?.config?.occupy_config
    },
    isEnabledOccupySetting() {
      return this.bookingPreset?.config?.occupy_config?.enable
    },
    displayTitle() {
      if (this.presetTimeField.format == 'datetime') {
        return '預約時間 + 日期模式'
      }

      if (this.presetTimeField.format == 'date') {
        return '預約日期模式'
      }

      return ''
    },
    presetLimitType() {
      return this.bookingPreset?.config?.occupy_config?.limit_type
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      return options
    },
  },
  data() {
    return {
      zh,
      slot_people: 0,
      showLoading: false,
      validationErrors: null,
      step: "",
      branchId: null,
      dateOptionIndex: 0,
      optionStartDate: null,
      optionEndDate: null,
      branch: {},
      bookingPreset: {
        name: "",
        config:{
          booking_mapping: {
            bookings: {
              start_at: null,
              start_at_by_branch: null
            }
          },
          fields:[],
          occupy_config: {
            enable: false,
            limit_type: null,
            is_check_branch: false
          }
        },
        branch_config:[]
      },
      isTyping: false,
      form: {
        business: {
          start_time: "08:00",
          end_time: "21:00",
          slot_minutes: 30,
          time_style: "select_style",
          limit_type: "unlimit",
          slot_people: 0,
          time_config_by: "default",
          date_config_by: "default",
          options: [],
        },
        time_options: []
      },
      option_text: '',
      presetTimeField: {},
      branchTimeField: {},
      hasBranchField: false,
      permissionChecker: new PermissionChecker(),
      disabledButton: false
    };
  },
  mounted() {
    this.bookingPreset.id = this.$route.params.id;
    this.branchId = this.$route.params.branch
    this.branch = this.branchOptions.find(branch => branch.value == this.branchId)
    this.fetchBookingPreset();
    this.navigateToStepFromCurrentRoute();
  },
  watch: {
    "form.business.limit_type": (value) => {
      if (value == 'unlimit') {
        this.form.business.slot_people = 0
      }
    },
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    createOptionByCalendar() {
      const startDate = new Date(this.optionStartDate);
      const endDate = this.optionEndDate;
      for (let date = new Date(endDate); date >= startDate; date.setDate(date.getDate() - 1)) {
        const dateString = date.toISOString().split('T')[0];
        const nextOptionIndex = this.dateOptionIndex + 1

        this.form.business.options.splice(nextOptionIndex, 0, {
          text: dateString,
          value: dateString,
        });

        // this.$nextTick(() => {
        //   this.$refs[`date_${nextOptionIndex}`][0].select();
        // });
      }

      this.startDate = null;
      this.endDate = null;
    },
    syncOptionValue(optionIndex) {
      this.form.business.options[optionIndex].value = this.form.business.options[optionIndex].text;
    },
    handleKeyDown({optionIndex}) {
      if(this.isTyping) return;
      this.syncOptionValue(optionIndex)
      this.createOption(optionIndex)
      this.$nextTick(() => {
        this.$refs[`date_${optionIndex + 1}`][0].select();
      });
    },
    compositionStart() {
      this.isTyping = true;
    },
    compositionEnd(optionIndex){
      this.isTyping = false
      this.syncOptionValue(optionIndex)
    },
    handlePaste(event, optionIndex){
      const pasteData = event.clipboardData;
      const pastedText = pasteData.getData('text');

      if (pastedText.includes('\n')) {
        const newOptions = pastedText.split('\n').map((text) => {
          return {
            text: text.trim(),
            value: text.trim(),
          };
        });
        this.form.business.options.splice(optionIndex + 1, 0, ...newOptions);
        this.form.business.options.splice(optionIndex, 1);

        // 由於此元件採用 rerendering 做 forceUpdate，使用 nextTick 無法完全確保元素已被渲染
        setTimeout(() => {
          this.$refs[`date_${optionIndex + newOptions.length - 1}`][0].select();
        }, 0);

      } else {
        this.form.business.options[optionIndex].value = pastedText;
      }
    },
    removeOption(optionIndex) {
      if(this.isOnlyOneOption()) return;
      this.form.business.options.splice(optionIndex, 1);
    },
    createOption(optionIndex) {
      const clickedSubjectValue = this.form.business.options[optionIndex].value;
      const nextOptionIndex = optionIndex + 1

      this.form.business.options.splice(nextOptionIndex, 0, {
        text: clickedSubjectValue,
        value: clickedSubjectValue,
      });

      // this.$nextTick(() => {
      //   this.$refs[`date_${nextOptionIndex}`][0].select();
      // });
    },
    isOnlyOneOption() {
      return this.form.business.options.length === 1;
    },
     presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + ".$errors", [])
        .map((error) => error.$message)
        .join(", ");
    },
    cancel() {
      this.$router.push({ name: "BookingSelectList", query: this.routeQuery });
    },
    goToStep(step) {
      if (!this.bookingPreset.id) {
        return;
      }

      if (this.step == step) {
        return;
      }

      this.step = step;
      if (location.hash !== this.step) {
        // NOTE: 這裡不使用 $router.replace，以避免 page rerender 導致 mounted 或其他 lifecycle hook 的內的 API 重複執行
        const newLocation = new URL(location.href);
        newLocation.hash = this.step;
        window.history.replaceState("", "", newLocation.href);
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit() {
      // const result = await this.v$.$validate();
      // if (!result) return false;
      try {
        this.showLoading = true;

        await bookingApi.setupBookingPresetBranch(
          this.bookingPreset.id,
          this.branchId,
          {
            config: {
              fields: [this.form.business],
              occupy_config: {
                time_options: this.form.time_options,
              }
            }
          }
        );
        await this.fetchBookingPreset();
        this.$swal("儲存成功", "", "success");

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBookingPreset() {
      if (!this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id, {
          branch_id: this.branchId
        });
        this.bookingPreset.branch_config = []
        this.$set(
          this,
          "bookingPreset",
          this.mergeDefaultAndDataModel(this.bookingPreset, response.data.data)
        );

        if (!this.bookingPreset?.config?.booking_mapping?.bookings?.start_at) {
          this.$swal("錯誤", "此預約設定檔案尚未設定開始時間指派", "error");
          this.disabledButton = true
          return
        }

        if (!this.bookingPreset?.config?.booking_mapping?.bookings?.start_at_by_branch) {
          this.$swal("錯誤", "預約開始時間必須設定分店關聯欄位", "error");
          this.disabledButton = true
          return
        }

        const field = this.deepGet(this.bookingPreset.config, 'fields', [])?.find((field) => field?._id == this.bookingPreset.config.booking_mapping.bookings.start_at);
        this.presetTimeField = field
        const timeField = this.deepGet(this.bookingPreset.branch_config, 'fields', [field])?.find((field) => field?._id == this.bookingPreset.config.booking_mapping.bookings.start_at);
        this.hasBranchField = !!this.deepGet(this.bookingPreset.branch_config, 'fields', [])?.find((field) => field?._id == this.bookingPreset.config.booking_mapping.bookings.start_at);
        this.branchTimeField = timeField
        if (timeField) {
          this.form.business = timeField
          this.formatBusiness()
          this.generateTimeOptions();
        }
        this.$forceUpdate()
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        // this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    changeTimeConfigBy() {
      if (this.form.business.time_config_by == 'default') {
        this.form.business = this.presetTimeField
        this.form.business.time_config_by = 'default'
      } else {
        if (this.branchTimeField) {
          this.form.business = this.branchTimeField
        }
        this.form.business.time_config_by = 'custom'
      }
      this.formatBusiness()
      this.$forceUpdate()
    },
    changeDateConfigBy() {
      if (this.form.business.date_config_by == 'default') {
        this.form.business = this.presetTimeField
        this.form.business.date_config_by = 'default'
      } else {
        if (this.branchTimeField) {
          this.form.business = this.branchTimeField
        }
        this.form.business.date_config_by = 'custom'
      }
      this.formatBusiness()
      this.$forceUpdate()
    },
    formatBusiness() {
      this.form.business.slot_minutes = this.form.business.slot_minutes ?? 30
      this.form.business.time_style = this.hasBranchField ? this.form.business.time_style : "default"
      this.form.business.date_style = this.hasBranchField ? this.form.business.date_style : "default"
      this.form.business.limit_type = this.hasBranchField ? this.form.business.limit_type : "unlimit"
      this.form.business.slot_people = this.form.business.slot_people ?? 0
      this.form.business.time_config_by = this.form.business.time_config_by ?? "default"
      this.form.business.date_config_by = this.form.business.date_config_by ?? "default"
      this.form.business.options = this.form.business.options ?? [{text: '', value: ''}]
      this.$forceUpdate()
    },
    isInclude(word, value) {
      if (value) {
        return value.includes(word)
      }
      return false
    },
    formatAndFetchPreset() {
      this.branchId = this.branch.value
      this.fetchBookingPreset()
    },
    generateTimeOptions() {
        let options = [];
        const slotMinutes = Number(this.form.business.slot_minutes);
        const startTime = this.form.business.start_time;
        const endTime = this.form.business.end_time;

        if (!startTime || !endTime) {
          return [];
        }
        if (slotMinutes < 1) {
          return;
        }
        // 將時間轉換為分鐘
      const timeToMinutes = (time) => {
        if (!time) {
          return 0;
        }
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes;
      };

      // 將分鐘轉回時間格式 "HH:MM"
      const minutesToTime = (minutes) => {
        const hours = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
      };
      const startMinutes = timeToMinutes(startTime);
      const endMinutes = timeToMinutes(endTime);

      // 計算實際的最後服務時間
      const lastServeMinutes = timeToMinutes(endTime);
      const adjustedLastServeMinutes = lastServeMinutes - Number(0);

      // 確保不會超過 24:00
      const actualEndMinutes = Math.min(endMinutes, adjustedLastServeMinutes);

      let currentMinutes = startMinutes;

      while (currentMinutes <= actualEndMinutes) {
        options.push({
          title: minutesToTime(currentMinutes),
          value: this.form.business.slot_people
        });
        currentMinutes = Number(currentMinutes) + slotMinutes;
        // 防止超過一天的範圍（1440 分鐘）
        if (currentMinutes >= 1440) {
          break;
        }
      }
        /* eslint-enable no-constant-condition */
        this.form.time_options = options;
      },
  },
};
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  &.has-checkbox {
    &:after {
      content: "\f134";
      font-family: "Material Design Icons";
      font-size: 16px;
    }
  }

  &.has-checkbox.has-checkbox--checked {
    &:after {
      content: "\f133";
      color: green;
    }
  }
}
</style>
